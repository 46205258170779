body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .btn-s {
        @apply ml-1 text-sm py-1 px-2 rounded
    }

    .btn-m {
        @apply ml-1 text-base py-1.5 px-3 rounded
    }

    .btn-b {
        @apply ml-1 text-lg py-2 px-4 rounded
    }

    .btn-f {
        @apply ml-1 py-1.5 px-3 w-full rounded
    }

    .btn-blue {
        @apply bg-blue-500 text-white hover:bg-blue-700;
    }

    .btn-indigo {
        @apply bg-indigo-500 text-white hover:bg-indigo-700;
    }

    .btn-green {
        @apply bg-green-500 text-white hover:bg-green-700;
    }

    .btn-red {
        @apply bg-red-500 text-white hover:bg-red-700;
    }

    .btn-yellow {
        @apply bg-yellow-500 text-white hover:bg-yellow-700;
    }

    .btn-teal {
        @apply bg-teal-500 text-white hover:bg-teal-700;
    }

    .btn-gray {
        @apply bg-gray-700 text-white hover:bg-gray-800;
    }

    .btn-solid {
        @apply appearance-none bg-transparent box-border text-[#3B3B3B] cursor-pointer inline-block font-semibold leading-[normal] text-center no-underline transition-all duration-300 ease-[cubic-bezier(.23,1,0.32,1)] select-none touch-manipulation will-change-transform m-0 rounded-[10px] border-2 border-solid border-[#1A1A1A] disabled:pointer-events-none hover:text-white hover:bg-[#1A1A1A] hover:shadow-[rgba(0,0,0,0.25)_0_8px_15px] hover:-translate-y-0.5 active:shadow-none active:translate-y-0;
    }

}